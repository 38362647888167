import {IUser} from 'app/modules/management/users/users/redux/slice'
import {createSlice, current, PayloadAction} from '@reduxjs/toolkit'
import {IResponsesCommon} from 'app/types/commonTypes'

interface IAttachment {
  uuid: string
  id: number
  rent_id: number
  name: string
  path: string
  mime_type: string
  size: number
  is_saved: boolean
  created_at: string
  updated_at: string
}

interface IMemo {
  uuid: string
  id: number
  user_id: number
  rent_id: number
  content: string
  type: number
  created_at: string
  updated_at: string
}

interface IFinancialCompanies {
  id: number
  logo: string
  month_fee?: string
  name: string
  created_at: string
  updated_at: string
  pivot: any
}
export interface IRenLease {
  id: number
  uuid: string
  user_id: number
  user: IUser
  status: number
  approval_rate: number
  type: number
  customer_type: number
  customer_name: string
  customer_phone: string
  origin: number
  category?: string
  manufacturer_id: number
  car_type_id: number
  engine_type_id: number
  car_level_id: number
  financial_company_id?: number
  inside_color: string
  outside_color: string
  pricing_list?: string[]
  total_price?: number
  contract_time: number
  distance: number
  pre_type: number
  pre_percent: number
  insurance_age: string
  is_insurance: number
  maintenance_type: number
  no_responsible_amount: number
  receive_address: string
  consultation_time: string
  created_at: string
  updated_at: string
  is_bad_debt: number
  attachments: IAttachment[]
  buying_method: number
  memo: IMemo[]
  is_sold_out: boolean
  financial_companies?: IFinancialCompanies[]
  fType?: number
  consultCnt: number
}
export interface ContainerRenLeaseState {
  renLease: IResponsesCommon<IRenLease>
  renLeaseDetail?: IRenLease
}

export const initialState: ContainerRenLeaseState = {
  renLease: {
    data: {
      current_page: 1,
      last_page: 1,
      data: [] as IRenLease[],
    },
  } as IResponsesCommon<IRenLease>,
}

const renLeaseSlice = createSlice({
  name: 'ren-lease-state',
  initialState,
  reducers: {
    getRenLease(state, action: PayloadAction<any>) {},
    getRenLeaseSuccess: (state, action: PayloadAction<IResponsesCommon<IRenLease>>) => {
      state.renLease = action.payload
    },
    updateRenLease: (state, action: PayloadAction<IRenLease>) => {
      const tempState = [...current(state).renLease.data.data]
      const tempIndex = tempState.findIndex((el) => el.uuid === action.payload.uuid)
      if (tempIndex >= 0) {
        state.renLease.data.data[tempIndex] = action.payload
      }
    },
    getRenLeaseDetail(state, action: PayloadAction<{uuid: string}>) {},
    getRenLeaseDetailSuccess: (state, action: PayloadAction<IRenLease>) => {
      state.renLeaseDetail = action.payload
    },
  },
})

export const {getRenLease, getRenLeaseSuccess, updateRenLease, getRenLeaseDetail, getRenLeaseDetailSuccess} =
  renLeaseSlice.actions

export default renLeaseSlice.reducer
